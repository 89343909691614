/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ReactWordcloud from "react-wordcloud";
import * as dateFns from "date-fns";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

const words = [
  { text: "C#", value: 79 },
  { text: ".NET Core", value: 77 },
  { text: ".NET Web API", value: 75 },
  { text: ".NET", value: 73 },
  { text: ".NET MVC", value: 71 },
  { text: "Visual Studio", value: 69 },
  { text: "VS Code", value: 67 },
  { text: "WCF", value: 65 },
  { text: "Web Services", value: 63 },
  { text: "Dapper", value: 61 },
  { text: "RESTful", value: 59 },
  { text: "TypeScript", value: 57 },
  { text: "HTML5", value: 55 },
  { text: "Redis", value: 54 },
  { text: "CSS", value: 53 },
  { text: "HTML", value: 51 },
  { text: "JavaScript", value: 49 },
  { text: "React JS", value: 47 },
  { text: "jQuery", value: 41 },
  { text: "Docker", value: 39 },
  { text: "OOP", value: 37 },
  { text: "MySQL", value: 35 },
  { text: "SQL Server", value: 33 },
  { text: "IBM DB2", value: 31 },
  { text: "Git", value: 29 },
  { text: "TDD", value: 27 },
  { text: "Unit Testing", value: 25 },
  { text: "DDD", value: 23 },
  { text: "VSTS", value: 21 },
  { text: "NUnit", value: 19 },
  { text: "WPF", value: 17 },
  { text: "Agile", value: 15 },
  { text: "Linux", value: 13 },
  { text: "NHibernate", value: 15 },
  { text: "Scrum", value: 15 },
  { text: "Microservices", value: 15 },
];

const options = {
  colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
  enableTooltip: true,
  deterministic: false,
  fontFamily: "Impact",
  fontSizes: [15, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 1,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 500,
};

const callbacks = {
  getWordTooltip: (word) => word.text,
};

const history = createBrowserHistory();

ReactGA.initialize("G-YMHLZFW5HM");
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
      typing: false,
      experienceYears: new Date(2002, 1, 1),
      frontbackYears: new Date(2004, 1, 1),
      tddYears: new Date(2011, 1, 1),
      htmlYears: new Date(2011, 1, 1),
      reactYears: new Date(2017, 1, 1),
      netCoreYears: new Date(2018, 1, 1),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.timeout = 0;
  }

  componentDidMount() {}

  calculateYears(oldDate, newDate) {
    return dateFns.differenceInYears(newDate, oldDate);
  }

  calculateMonths(oldDate, newDate) {
    return dateFns.differenceInMonths(newDate, oldDate);
  }

  handleChange(e) {
    this.setState({ typing: true });

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ typing: false });
    }, 3000);

    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  validateForm() {
    let validEmail =
      this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ||
      this.state.email.indexOf(":") > -1;

    return (
      this.state.name.length > 0 && this.state.message.length > 0 && validEmail
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    const { name, email, message } = this.state;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      crossDomain: true,
    };

    return fetch(
      "https://api.filipepimentel.dev/emails/send?name=" +
        name +
        "&email=" +
        email +
        "&message=" +
        message,
      requestOptions
    )
      .then((response) => {
        alert("Thank you for your message.");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    let yearsAtShc = this.calculateYears(new Date(2022, 6, 1), new Date());
    let monthsAtShc = this.calculateMonths(new Date(2022, 6, 1), new Date());
    let experienceYears = this.calculateYears(
      this.state.experienceYears,
      new Date()
    );
    let frontbackYears = this.calculateYears(
      this.state.frontbackYears,
      new Date()
    );
    let tddYears = this.calculateYears(this.state.tddYears, new Date());
    let htmlYears = this.calculateYears(this.state.htmlYears, new Date());
    let reactYears = this.calculateYears(this.state.reactYears, new Date());
    let netCoreYears = this.calculateYears(this.state.netCoreYears, new Date());

    const { name, email, message } = this.state;

    return (
      <div className="App">
        <div className="preloader">
          <span className="loader">
            <span className="loader-inner"></span>
          </span>
        </div>

        <div className="right-br"></div>

        <div className="left-br"></div>

        <div className="fixed-right-socials">
          <ul className="list-unstiled">
            <li>
              <a href="https://github.com/filipepimentel" target="_blank">
                <i className="fa fa-github" title="GitHub"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/filipepimentel"
                target="_blank"
              >
                <i className="fa fa-linkedin" title="LinkedIn"></i>
              </a>
            </li>
            <li>
              <a
                href="https://join.skype.com/invite/wLXDzJB3rPeW"
                target="_blank"
              >
                <i className="fa fa-skype" title="Skype"></i>
              </a>
            </li>
          </ul>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-2 sidebar">
              <div className="col-sm-2 sidebarWrapper">
                <img className="user-pic" src="images/profile_pic.jpg" alt="" />
                <div className="signature"></div>

                <div className="menu">
                  <div className="toggle-btn">
                    <span className="lines"></span>
                  </div>
                  <ul className="nav">
                    <li className="active">
                      <a href="#about">About me</a>
                    </li>

                    <li>
                      <a href="#skills">Skills</a>
                    </li>
                    <li>
                      <a href="#services">Services</a>
                    </li>
                    <li>
                      <a href="#portfolio">Latest Projects</a>
                    </li>
                    <li>
                      <a href="#resume">Work Experience</a>
                    </li>
                    <li>
                      <a href="#education">Education</a>
                    </li>
                    <li>
                      <a href="#certifications">Certifications</a>
                    </li>
                    <li>
                      <a href="#contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="about">
          <div className="about-background">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-9 col-sm-offset-3 aboutWrapper">
                  <div className="nav-extra hidden-xs">
                    <a href="#contact" className="btn btn-primary">
                      Get in touch
                    </a>
                  </div>
                  <div className="section-title">
                    <h2>
                      <span>01</span>About me
                    </h2>
                  </div>
                  <h1>
                    Hello, I am <strong>Filipe Pimentel</strong> <br />
                    Software Engineer | GC Holder{" "}
                    <span className="bg-color">MCTS</span>
                  </h1>
                  <p className="paragraphFont">
                    I have <strong>{experienceYears}+ years</strong> of
                    experience in the IT industry, specializing in software
                    development. With expertise in Microsoft .NET, I have{" "}
                    <strong>{frontbackYears}+ years</strong> of experience in
                    back-end and front-end projects. Additionally, I have{" "}
                    <strong>{tddYears}+ years</strong> of experience in
                    test-driven development, unit testing, and code coverage.
                    For front-end technologies, I have{" "}
                    <strong>{htmlYears}+ years</strong> of experience in HTML,
                    CSS, and JavaScript, and{" "}
                    <strong>{reactYears}+ years</strong> in React JS,
                    Typescript, HTML5, Bootstrap, and ES6. My experience also
                    includes <strong>{netCoreYears}+ years</strong> of working
                    on .NET Core. <strong>Green card</strong> holder.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="skills">
          <div className="services-background">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-9 col-sm-offset-3 servicesWrapper">
                  <div className="section-title">
                    <h2>
                      <span>02</span>Skills
                    </h2>
                  </div>

                  <div style={{ height: "100%", width: "100%" }}>
                    {!this.state.typing && (
                      <ReactWordcloud
                        options={options}
                        words={words}
                        callbacks={callbacks}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services">
          <div className="services-background">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-9 col-sm-offset-3 servicesWrapper">
                  <div className="section-title">
                    <h2>
                      <span>03</span>Services
                    </h2>
                  </div>

                  <div className="service-box">
                    <div>
                      <i className="icon icon_globe-2"></i>
                    </div>
                    <div>
                      <h3>Web Development</h3>
                      <p className="paragraphFont">
                        Most of the projects I worked on were "apps" that look
                        like native, because browsers are now much more than a
                        URL bar.
                      </p>
                    </div>
                  </div>

                  <div className="service-box">
                    <div>
                      <i className="icon icon_mobile"></i>
                    </div>
                    <div>
                      <h3>Mobile Development</h3>
                      <p className="paragraphFont">
                        I have worked on some mobile development projects and
                        involve creating apps that run on mobile devices, such
                        as iPhones and Android devices.
                      </p>
                    </div>
                  </div>

                  <div className="service-box">
                    <div>
                      <i className="icon icon_laptop"></i>
                    </div>
                    <div>
                      <h3>Application Development</h3>
                      <p className="paragraphFont">
                        In many cases, "standard" applications that perform
                        their functions on traditional desktop operating systems
                        are essential and I have already developed some of
                        these.
                      </p>
                    </div>
                  </div>

                  <div className="service-box">
                    <div>
                      <i className="icon icon_cloud_alt"></i>
                    </div>
                    <div>
                      <h3>Back-end Development</h3>
                      <p className="paragraphFont">
                        Back-end development and its APIs is the work that goes
                        on behind the scenes to ensure that the front-end
                        program gets started.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio">
          <div className="portfolio-background">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-9 col-sm-offset-3 portfolioWrapper">
                  <div className="section-title">
                    <h2 className="pull-left">
                      <span>04</span>Latest Projects
                    </h2>
                    <div className="portfolio-filter pull-right">
                      <ul>
                        <li
                          className="btn btn-default filter active"
                          data-filter="all"
                        >
                          Show All
                        </li>
                        <li
                          className="btn btn-default filter"
                          data-filter=".category-1"
                        >
                          Web
                        </li>
                        <li
                          className="btn btn-default filter"
                          data-filter=".category-2"
                        >
                          Mobile
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div id="mix-container">
                      <div className="col-xs-12 col-sm-4 mix category-1">
                        <div className="imageWrapper">
                          <a className="lightbox" href="#popup-1">
                            <img src="images/lumoslms.png" alt="" />
                            <div className="imageHover">
                              <div>
                                <h3>LMS</h3>
                                <span>Lumos Language School</span>
                              </div>
                            </div>
                          </a>

                          <div id="popup-1" className="mfp-hide popup-box">
                            <img src="images/lumoslms.png" alt="" />
                            <div>
                              <h3>LMS</h3>
                              <p className="paragraphFont">
                                Development of the Learning Management System
                                application at Lumos Language School, using
                                React JS for the front end and ASP .NET Core for
                                the back end.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 mix category-2">
                        <div className="imageWrapper">
                          <a className="lightbox" href="#popup-2">
                            <img src="images/mongeral.png" alt="" />
                            <div className="imageHover">
                              <div>
                                <h3>e-SIM</h3>
                                <span>MAG Seguros</span>
                              </div>
                            </div>
                          </a>

                          <div id="popup-2" className="mfp-hide popup-box">
                            <img src="images/mongeral.png" alt="" />
                            <div>
                              <h3>e-SIM</h3>
                              <p className="paragraphFont">
                                Development and maintenance of MAG Seguros eSIM
                                application, with ASP .NET Core, TDD and Agile
                                Methodologies.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-xs-12 col-sm-4 mix category-1">
                        <div className="imageWrapper">
                          <a className="lightbox" href="#popup-3">
                            <img src="images/wdcarpentry.png" alt="" />
                            <div className="imageHover">
                              <div>
                                <h3>Website</h3>
                                <span>WD Carpentry</span>
                              </div>
                            </div>
                          </a>

                          <div id="popup-3" className="mfp-hide popup-box">
                            <img src="images/wdcarpentry.png" alt="" />
                            <div>
                              <h3>Website</h3>
                              <p className="paragraphFont">
                                WebSite development for the company WD
                                Carpentry, using React JS and publishing with
                                Docker.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="col-xs-12 col-sm-4 mix category-2">
                        <div className="imageWrapper">
                          <a className="lightbox" href="#popup-3">
                            <img src="images/lumosapp.png" alt="" />
                            <div className="imageHover">
                              <div>
                                <h3>Lumos App</h3>
                                <span>Lumos Language School</span>
                              </div>
                            </div>
                          </a>

                          <div id="popup-3" className="mfp-hide popup-box">
                            <img src="images/lumosapp.png" alt="" />
                            <div>
                              <h3>Lumos App</h3>
                              <p className="paragraphFont">
                                Development of the Lumos App, made in Xamarin
                                and communicating with a back end made in ASP
                                .NET Core.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 mix category-2">
                        <div className="imageWrapper">
                          <a className="lightbox" href="#popup-4">
                            <img src="images/lumostrip.png" alt="" />
                            <div className="imageHover">
                              <div>
                                <h3>Website</h3>
                                <span>Lumos Trip</span>
                              </div>
                            </div>
                          </a>

                          <div id="popup-4" className="mfp-hide popup-box">
                            <img src="images/lumostrip.png" alt="" />
                            <div>
                              <h3>Website</h3>
                              <p className="paragraphFont">
                                Development of the Lumos Trip application, using
                                React JS for the front end and ASP .NET Core for
                                the back end.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 mix category-1">
                        <div className="imageWrapper">
                          <a className="lightbox" href="#popup-5">
                            <img src="images/flexlocacao.png" alt="" />
                            <div className="imageHover">
                              <div>
                                <h3>Flex Locacao</h3>
                                <span>Interart</span>
                              </div>
                            </div>
                          </a>

                          <div id="popup-5" className="mfp-hide popup-box">
                            <img src="images/flexlocacao.png" alt="" />
                            <div>
                              <h3>Flex Locacao</h3>
                              <p className="paragraphFont">
                                Member of the Interart development team that
                                built the Flex Locacao application, using
                                Javascript, JQuery, PHP, etc.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 mix category-1">
                        <div className="imageWrapper">
                          <a className="lightbox" href="#popup-6">
                            <img src="images/meuscrum.png" alt="" />
                            <div className="imageHover">
                              <div>
                                <h3>Meu Scrum</h3>
                                <span>Interart</span>
                              </div>
                            </div>
                          </a>

                          <div id="popup-6" className="mfp-hide popup-box">
                            <img src="images/meuscrum.png" alt="" />
                            <div>
                              <h3>Meu Scrum</h3>
                              <p className="paragraphFont">
                                Member of the Interart development team that
                                built the Meu Scrum application, using
                                Javascript, JQuery, PHP, etc.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 mix category-1">
                        <div className="imageWrapper">
                          <a className="lightbox" href="#popup-7">
                            <img src="images/webmastertools.png" alt="" />
                            <div className="imageHover">
                              <div>
                                <h3>WebMaster Tools</h3>
                                <span>Interart</span>
                              </div>
                            </div>
                          </a>

                          <div id="popup-7" className="mfp-hide popup-box">
                            <img src="images/webmastertools.png" alt="" />
                            <div>
                              <h3>WebMaster Tools</h3>
                              <p className="paragraphFont">
                                Member of the Interart development team that
                                built the WebMaster Tools application, using
                                Javascript, JQuery, PHP, etc.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 mix category-1">
                        <div className="imageWrapper">
                          <a className="lightbox" href="#popup-8">
                            <img src="images/onescrum.png" alt="" />
                            <div className="imageHover">
                              <div>
                                <h3>One Scrum</h3>
                                <span>Interart</span>
                              </div>
                            </div>
                          </a>

                          <div id="popup-8" className="mfp-hide popup-box">
                            <img src="images/onescrum.png" alt="" />
                            <div>
                              <h3>One Scrum</h3>
                              <p className="paragraphFont">
                                Member of the Interart development team that
                                built the One Scrum application, using
                                Javascript, JQuery, PHP, etc.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 mix category-1">
                        <div className="imageWrapper">
                          <a className="lightbox" href="#popup-9">
                            <img src="images/gestorflex.png" alt="" />
                            <div className="imageHover">
                              <div>
                                <h3>Gestor Flex</h3>
                                <span>Interart</span>
                              </div>
                            </div>
                          </a>

                          <div id="popup-9" className="mfp-hide popup-box">
                            <img src="images/gestorflex.png" alt="" />
                            <div>
                              <h3>Gestor Flex</h3>
                              <p className="paragraphFont">
                                Member of the Interart development team that
                                built the Gestor Flex application, using
                                Javascript, JQuery, PHP, etc.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="resume">
          <div className="resume-background">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-9 col-sm-offset-3 resumeWrapper">
                  <div className="section-title">
                    <h2>
                      <span>05</span>Work Experience
                    </h2>
                  </div>

                  <div className="resume-box">
                    <div>
                      <img
                        src="images/Shc.png"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "0" }}>Software Engineer</h3>
                      <h3>
                        Supplemental Health Care, Cottonwood Heights, UT, US
                      </h3>
                      <span>
                        {"Jul 2022 - Present (" +
                          (yearsAtShc > 0 ? yearsAtShc + " yrs " : "") +
                          monthsAtShc +
                          " mos)"}
                      </span>

                      <p className="paragraphFont">
                        Working as a Software Engineer at Supplemental Health
                        Care. Part of the Scrum team working on a system that is
                        in production and being modernized. Also working in
                        software development, improving processes, and
                        optimizing overall performance. Currently the system has
                        modules in .NET Framework and .NET Core, and modules in
                        MVC are being migrated to React JS. All databases are on
                        SQL Server.
                      </p>
                    </div>
                  </div>

                  <div className="resume-box">
                    <div>
                      <img
                        src="images/efetivox.jpg"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "0" }}>Software Engineer</h3>
                      <h3>Efetivox, Utah, US</h3>
                      <span>{"Feb 2022 - Jul 2022 (6 mos)"}</span>

                      <p className="paragraphFont">
                        Development of projects at Efetivox. Most web projects
                        were divided into two parts, the front end being
                        developed with React JS and communicating with the
                        back-end, which was developed with Microsoft ASP NET
                        Core, with continuous delivery with Docker (CI/CD). In
                        general, the back-end was hosted on Linux servers, which
                        received calls from web and mobile applications.
                      </p>
                    </div>
                  </div>

                  <div className="resume-box">
                    <div>
                      <img
                        src="images/lumos.jpg"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "0" }}>Software Engineer</h3>
                      <h3>Lumos Language School, Utah, US</h3>
                      <span>{"Jan 2019 - Nov 2021 (2 yrs 11 mos)"}</span>
                      <p className="paragraphFont">
                        Development of two software projects at Lumos Language
                        School. The first was the Lumos app, launched on iOS and
                        Android platforms, where students can check their
                        grades, classes, and attendance. It was built in Xamarin
                        and communicates with an API built with ASP NET Core.
                        The second project is the Lumos HUB. This software is
                        for managing enrollments and assessments of students,
                        teachers, and the administration of the school system.
                      </p>
                    </div>
                  </div>

                  <div className="resume-box">
                    <div>
                      <img
                        src="images/somosadd.png"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "0" }}>
                        Senior .NET Developer
                      </h3>
                      <h3>Add Technologies, Brazil</h3>
                      <span>{"Apr 2011 - Oct 2021 (10 yrs 7 mos)"}</span>
                      <p className="paragraphFont">
                        Worked as a software developer at a life insurance
                        company. I was assigned to work on three modules, and
                        the biggest one was based on complex mathematical
                        formulas. I used Visual Studio as the main tool, with
                        .NET and C#. DDD, TDD, Design Patterns, Unit Testing,
                        NUnit, NHibernate, Dapper, MVC, SQL Server, VSTS and
                        Scrum were also used.
                      </p>
                    </div>
                  </div>

                  <div className="resume-box">
                    <div>
                      <img
                        src="images/alterdata.png"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "0" }}>
                        Senior .NET Developer
                      </h3>
                      <h3>Alterdata Software, Brazil</h3>
                      <span>{"Feb 2002 - Mar 2011 (9 yrs 2 mos)"}</span>
                      <p className="paragraphFont">
                        Worked for nine years at Alterdata as a software
                        developer. I developed systems for different segments
                        such as business automation, financial control, and
                        CRM/ERP. Some modules were developed in WPF, with
                        automatic updating by ClickOnce, but most were MVC for
                        Web Applications. I used Microsoft Visual Studio with
                        C#, .NET Framework, Unit Testing, SVN, CSS, JQuery,
                        HTML, Web Services and I was part of the Scrum team.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="education">
          <div className="resume-background">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-9 col-sm-offset-3 resumeWrapper">
                  <div className="section-title">
                    <h2>
                      <span>06</span>Education
                    </h2>
                  </div>

                  <div className="resume-box">
                    <div>
                      <img
                        src="images/estacio.jpg"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "0" }}>
                        Bachelor of Science in Computer Science
                      </h3>
                      <h3>Estacio de Sa University, Rio de Janeiro, Brazil</h3>
                      <span>January 2001 - December 2004</span>
                      <p className="paragraphFont">
                        Bachelor of Science in Computer Science, with emphasis
                        on data processing and software development.
                      </p>
                    </div>
                  </div>

                  <div className="resume-box">
                    <div>
                      <img
                        src="images/lumos.jpg"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "0" }}>
                        Intensive English Course
                      </h3>
                      <h3>Lumos Language School, Utah, US</h3>
                      <span>September 2018 - October 2021</span>
                      <p className="paragraphFont">
                        Intensive English Course at Lumos Language School.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="certifications">
          <div className="resume-background">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-9 col-sm-offset-3 resumeWrapper">
                  <div className="section-title">
                    <h2>
                      <span>07</span>Certifications
                    </h2>
                  </div>

                  <div className="resume-box" style={{ minHeight: "10rem" }}>
                    <div>
                      <img
                        src="images/microsoft.png"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3>Microsoft Certified Technology Specialist (MCTS)</h3>
                      <p className="paragraphFont">
                        Microsoft Certified Technology Specialist (MCTS) .NET
                        Framework 4, Web Applications Development
                      </p>{" "}
                      <br />
                    </div>
                  </div>

                  <div className="resume-box" style={{ minHeight: "10rem" }}>
                    <div>
                      <img
                        src="images/redis.png"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3>Redis for .NET Developers</h3>
                      <p className="paragraphFont">
                        Redis for .NET Developers at Redis University
                      </p>{" "}
                      <br />
                    </div>
                  </div>

                  <div className="resume-box" style={{ minHeight: "10rem" }}>
                    <div>
                      <img
                        src="images/infnet.png"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3>Microsoft ASP.NET Web Developer</h3>
                      <p className="paragraphFont">
                        Web Developer ASP.NET – I550, M4994, M2956, M2310c,
                        M6464, M2957 at Infnet Institute, Brazil
                      </p>{" "}
                      <br />
                    </div>
                  </div>

                  <div className="resume-box" style={{ minHeight: "10rem" }}>
                    <div>
                      <img
                        src="images/digidata.png"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3>Microsoft Visual Studio - C# and ASP.NET</h3>
                      <p className="paragraphFont">
                        Programming in Visual Studio with C# and ASP.NET at
                        Digidata Courses, Brazil
                      </p>{" "}
                      <br />
                      <br />
                    </div>
                  </div>

                  <div className="resume-box" style={{ minHeight: "10rem" }}>
                    <div>
                      <img
                        src="images/certiprof.png"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3>Scrum Foundation Professional Certificate</h3>
                      <p className="paragraphFont">
                        Scrum Foundation Professional Certificate at CertiProf
                      </p>
                      <br />
                      <br />
                    </div>
                  </div>

                  <div className="resume-box" style={{ minHeight: "10rem" }}>
                    <div>
                      <img
                        src="images/scrumstudy.png"
                        alt=""
                        className="imgExperience"
                      />
                    </div>
                    <div>
                      <h3>Scrum Fundamentals Certified</h3>

                      <p className="paragraphFont">
                        Scrum Fundamentals Certified at ScrumStudy
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact">
          <div className="contact-background">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-9 col-sm-offset-3 contactWrapper">
                  <div className="section-title">
                    <h2>
                      <span>08</span>Get In Touch
                    </h2>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-6 contact-form">
                      <div className="widget">
                        <form id="contactform" onSubmit={this.handleSubmit}>
                          <div className="input-style">
                            <div>
                              <input
                                className="form-control"
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Name"
                                data-rule="minlen:4"
                                data-msg="Please enter at least 4 chars"
                                required
                                value={name}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div>
                              <input
                                className="form-control"
                                type="email"
                                id="email"
                                name="email"
                                placeholder="E-Mail"
                                data-rule="email"
                                data-msg="Please enter a valid email"
                                required
                                value={email}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="text-style">
                            <textarea
                              className="form-control"
                              name="message"
                              id="message"
                              placeholder="Message"
                              data-rule="required"
                              data-msg="Please write something for me"
                              required
                              value={message}
                              onChange={this.handleChange}
                            ></textarea>
                          </div>
                          <input
                            type="submit"
                            id="submit"
                            className="btn btn-primary"
                            name="submit"
                            value="Send Message"
                            disabled={!this.validateForm()}
                          />
                        </form>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 contact-info">
                      <div className="widget">
                        <ul>
                          <li>
                            <h3>
                              <span
                                className="icon_pin"
                                aria-hidden="true"
                              ></span>{" "}
                              Address
                            </h3>
                            <p className="paragraphFont">Orlando, FL, USA</p>
                          </li>
                          <li>
                            <h3>
                              <span
                                className="icon_mail"
                                aria-hidden="true"
                              ></span>{" "}
                              Email
                            </h3>
                            <p className="paragraphFont">
                              hello@filipepimentel.dev
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="copyright"></footer>
      </div>
    );
  }
}

export default App;
